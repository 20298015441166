import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
} from "@mui/material";

export const serviceTypesOptions = [
  { value: "DELIVERY", text: "Delivery" },
  { value: "BALCAO", text: "Balcão" },
  { value: "MESA", text: "Mesa" },
  { value: "COMANDA", text: "Comanda" },
];

function ServiceTypes({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant="standard" sx={{ minWidth: 150 }}>
      <InputLabel>Tipos de Atendimento</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        {serviceTypesOptions.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ServiceTypes;
