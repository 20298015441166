import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  BooleanField,
  DateField,
  FunctionField,
} from "react-admin";

import ComandaFilter from "./ComandaFilter";

const ComandaList = (props) => (
  <List {...props} filters={<ComandaFilter />} exporter={false}>
    <Datagrid>
      <TextField source="codigo" label="Código" />
      <TextField source="movimentoId" label="Movimento id" />
      <TextField source="parceiroId" label="Parceiro id" />
      <BooleanField source="prepaga" />
      <BooleanField source="bloqueada" />
      <BooleanField source="conferencia" />
      <TextField source="observacao" label="Observação" />
      <DateField
        source="ultimo"
        label="Último atendimento"
        showTime
        locales="pt-BR"
      />
      <FunctionField
        render={(record) =>
          record.movimentoId ? null : <EditButton record={record} />
        }
      />
    </Datagrid>
  </List>
);

export default ComandaList;
