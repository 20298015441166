import React from "react";
import { SelectInput } from "react-admin";

export const Regime = {
  SIMPLES: { id: 1, name: "1 - Simples nacional" },
  SIMPLES_EXCESSO: {
    id: 2,
    name: "2 - Simples nacional com excesso de receita bruta",
  },
  NORMAL: { id: 3, name: "3 - Regime normal" },
  MEI: { id: 4, name: "4 - MEI" },
};

const OPCOES = Object.keys(Regime).map((k) => Regime[k]);

const RegimeInput = (props) => {
  return <SelectInput {...props} choices={OPCOES} />;
};

export default RegimeInput;
