import React from "react";
import {
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  maxLength,
  FormDataConsumer,
  AutocompleteInput,
  Labeled,
} from "react-admin";
import { v4 as uuidv4 } from "uuid";
import { Grid, Typography } from "@mui/material";
import { IdDates } from "../../common/form/ConditionalInputs";
import ArrayInputRemoveButton from "../../common/form/ArrayInputRemoveButton";
import LazyReferenceInput from "../../common/form/LazyReferenceInput";
import NumberInputSimple from "../../common/form/NumberInputSimple";
import { withUser } from "../../common/util/hocs";

const validateReq = [required()];
const validateNome = [required(), maxLength(100)];

function GrupoItem({ record, formData, itemData, getSource }) {
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item>
        <LazyReferenceInput
          record={record}
          source={getSource("grupoId")}
          nameSource={getSource("grupo")}
          label="Grupo"
          reference="grupos"
          validate={validateReq}
        >
          <AutocompleteInput optionText="nome" />
        </LazyReferenceInput>
      </Grid>
      <Grid item>
        <ArrayInputRemoveButton
          formData={formData}
          itemData={itemData}
          getSource={getSource}
        />
      </Grid>
    </Grid>
  );
}

function KdsForm(props) {
  const { record, loja } = props;
  const kdsUrl = `https://kds.sistemapallas.com.br/${loja.id}/${loja.uuid}/${record.uuid}`;

  const initialValues = {
    uuid: uuidv4(),
  };

  return (
    <SimpleForm
      {...props}
      toolbar={props.toolbar}
      initialValues={initialValues}
      redirect="list"
    >
      <IdDates />
      <TextInput
        source="nome"
        label="Nome do KDS"
        validate={validateNome}
        fullWidth
      />

      <NumberInputSimple
        source="minutos_atraso"
        label="Tempo em minutos para marcar 'Em atraso'"
        fullWidth
      />

      {record.id && (
        <Labeled label="Endereço do KDS" fullWidth>
          <a href={kdsUrl} target="_blank" rel="noopener noreferrer">
            {kdsUrl}
          </a>
        </Labeled>
      )}

      <ArrayInput source="grupos" label="Grupos" fullWidth>
        <SimpleFormIterator
          disableReordering
          disableRemove
          TransitionProps={{ enter: false, exit: false }}
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource }) => (
              <GrupoItem
                record={record}
                formData={formData}
                itemData={scopedFormData}
                getSource={getSource}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Typography variant="body2" fullWidth sx={{ mb: 5 }}>
        * Caso nenhum grupo seja escolhido, este KDS exibirá todos os produtos.
      </Typography>
    </SimpleForm>
  );
}

export default withUser(KdsForm);
