import React, { useState } from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LoadingButton from "../../common/LoadingButton";
import { fetchJSON } from "../../dataProvider";
import { useNotify } from "react-admin";

const WhatsappDesinstalarButton = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  if (!record.number) return null;

  const desinstalarWhatsapp = async () => {
    try {
      setLoading(true);
      await fetchJSON("Whatsapp/roboDesinstalarNumber", {
        method: "DELETE",
        body: {
          number: record.number,
        },
      });
    } catch (err) {
      console.log(JSON.stringify(err));
      notify("Ocorreu um erro ao desinstalar Whatsapp");
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="outlined"
      color="error"
      text="Desinstalar"
      loading={loading}
      onClick={desinstalarWhatsapp}
      icon={<DeleteForeverIcon />}
    />
  );
};

export default WhatsappDesinstalarButton;
