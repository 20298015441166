import React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  maxLength,
} from "react-admin";
import { IdDates } from "../../common/form/ConditionalInputs";

const validateCodigo = [required(), maxLength(10)];

function ComandasForm(props) {
  return (
    <SimpleForm {...props} redirect="list">
      <IdDates />
      <TextInput source="codigo" label="Código" validate={validateCodigo} />
      <BooleanInput source="prepaga" />
      <BooleanInput source="bloqueada" />
    </SimpleForm>
  );
}

export default ComandasForm;
