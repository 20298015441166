import React, { useState } from "react";
import packageJSON from "../../package.json";
import { useSelector } from "react-redux";
import { useNotify } from "react-admin";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import {
  useTheme,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem as SelectMenuItem,
  InputLabel,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import TrendingUp from "@material-ui/icons/TrendingUp";
import SettingsIcon from "@material-ui/icons/Settings";
import TuneIcon from "@material-ui/icons/Tune";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import StoreIcon from "@material-ui/icons/Store";
import PublicIcon from "@material-ui/icons/Public";
import SubMenu from "./SubMenu";
import grupo from "../resources/grupo";
import imposto from "../resources/imposto";
import impressora from "../resources/impressora";
import pergunta from "../resources/pergunta";
import resposta from "../resources/resposta";
import produto from "../resources/produto";
import produto_combo from "../resources/produto_combo";
import unidade from "../resources/unidade";
import {
  iconCaixaTodos,
  caixaTodos,
  caixaConferidos,
  caixaNaoConferidos,
} from "../resources/caixa";
import moeda from "../resources/moeda";
import moeda_app from "../resources/moeda_app";
import moeda_tipo from "../resources/moeda_tipo";
import moeda_adquirente from "../resources/moeda_adquirente";
import ncm from "../resources/ncm";
import nps from "../resources/nps";
import cest from "../resources/cest";
import { lojaAdmin } from "../resources/loja";
import loja_entrega from "../resources/loja_entrega";
import loja_horario from "../resources/loja_horario";
import loja_moeda_app from "../resources/loja_moeda_app";
import promocao_app from "../resources/promocao_app";
import usuario from "../resources/usuario";
import log from "../resources/log";
import appPedido from "../resources/app pedido";
import certifidadoDigital from "../resources/certificado_digital";
import parceiro from "../resources/parceiro";
import comanda from "../resources/comanda";
import usuario_loja from "../resources/usuario_loja";
import { curvaABC } from "../reports/ReportCurvaABC";
import { curvaABCTipoMov } from "../reports/ReportCurvaABCTipoMov";
import { engenhariaCardapio } from "../reports/ReportEngenhariaCardapio";
import { ticketMedio } from "../reports/ReportTicketMedio";
import { faturamentoMoeda } from "../reports/ReportFaturamentoMoeda";
import { productsTop } from "../reports/Report10Products";
import { salesByChannel } from "../reports/ReportSalesByChannel";
import { salesByServiceType } from "../reports/ReportSalesByServiceType";
import { withUser } from "../common/util/hocs";
import { formatCNPJCPF } from "../common/util/formatter";
import { mudaLoja } from "../app/App";
import { reportDailyInvoicings } from "../reports/ReportDailyInvoicings/ReportDailyInvoicings";
import { reportWeeklyPerformance } from "../reports/ReportWeeklyPerformance/ReportWeeklyPerformance";
import { reportCurvaABC } from "../reports/ReportCurvaABC/ReportCurvaABC";
import { reportResult } from "../reports/ReportResult";
import estoque from "../resources/estoque";
import pdv_local from "../resources/pdv_local";
import pedidos from "../resources/pedidos";
import nfe_eventos from "../resources/nfe_tomada";
import nfe_emissao from "../resources/nfe_emissao";
import movimento from "../resources/movimento";
import inventario from "../resources/inventario";
import conta, { iconConta } from "../resources/conta";
import conta_classificacao from "../resources/conta_classificacao";
import conta_centro_custo from "../resources/conta_centro_custo";
import whatsapp from "../resources/whatsapp";
import ifood_oauth from "../resources/ifood_oauth";
import fidelidade from "../resources/fidelidade";
import kds from "../resources/kds";
import { reportBillsByClassification } from "../reports/ReportBills/ReportBillsByClassification";
import { reportBillsByPartners } from "../reports/ReportBills/ReportBillsByPartner";
import { reportBillsCashier } from "../reports/ReportBills/ReportBillsCashier";
import { saldoCorrentistas } from "../reports/ReportSaldoCorrentistas";
import { itensDeletados } from "../reports/ReportItensDeletados";
import { partnerCommission } from "../reports/ReportPartnerComission";
import MenuItem from "./MenuItem";
import { publicarMenu } from "../permissionsHelper";

const items = [
  {
    name: "Admin",
    icon: <SettingsIcon />,
    items: [
      appPedido,
      log,
      usuario,
      lojaAdmin,
      certifidadoDigital,
      whatsapp,
      ifood_oauth,
      moeda_adquirente,
      moeda_tipo,
      moeda_app,
      ncm,
      cest,
      nps,
    ],
  },
  {
    name: "Cardápio",
    icon: <MenuBookIcon />,
    items: [grupo, produto, produto_combo, pergunta, resposta],
  },
  {
    name: "Cadastros",
    icon: <TuneIcon />,
    items: [
      usuario_loja,
      parceiro,
      imposto,
      impressora,
      unidade,
      moeda,
      kds,
      comanda,
    ],
  },
  {
    name: "Cardápio Digital",
    icon: <LocalLibraryIcon />,
    items: [
      fidelidade,
      promocao_app,
      loja_entrega,
      loja_horario,
      loja_moeda_app,
      reportCurvaABC,
      reportDailyInvoicings,
      reportWeeklyPerformance,
    ],
  },
  {
    name: "Caixas",
    icon: iconCaixaTodos,
    items: [
      caixaTodos,
      caixaNaoConferidos,
      caixaConferidos,
      pdv_local,
      movimento,
    ],
  },
  {
    name: "Relatórios PDV",
    icon: <TrendingUp />,
    items: [
      faturamentoMoeda,
      curvaABC,
      curvaABCTipoMov,
      productsTop,
      ticketMedio,
      salesByChannel,
      salesByServiceType,
      partnerCommission,
      itensDeletados,
      engenhariaCardapio,
      saldoCorrentistas,
    ],
  },
  {
    name: "Estoque",
    icon: <ViewComfyIcon />,
    items: [estoque, inventario, pedidos, nfe_eventos, nfe_emissao],
  },
  {
    name: "Contas",
    icon: iconConta,
    items: [
      conta,
      conta_classificacao,
      conta_centro_custo,
      reportBillsByClassification,
      reportBillsCashier,
      reportBillsByPartners,
      reportResult,
    ],
  },
];

const Menu = (props) => {
  const { dense, logout } = props;
  const [state, setState] = useState({});

  const notify = useNotify();
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const handleSelect = (event) => {
    var n = window.location.href.lastIndexOf("/");
    var result = window.location.href.substring(n + 1);
    if (result === "" || isNaN(result)) {
      mudaLoja(event.target.value);
    } else {
      notify("Não é possível trocar de CNPJ durante a edição.", "warning");
    }
  };

  const { usuario, loja, permissao, admin_user, admin_user_active } = props;
  const label = loja.cnpj.length > 11 ? "CNPJ " : "CPF ";
  const nomeLoja =
    loja.nome_fantasia.length > 16
      ? loja.nome_fantasia.substring(0, 16)
      : loja.nome_fantasia;

  return (
    <Box sx={{ pb: 4 }}>
      {loja.fotoUrl && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img alt={loja.nome_fantasia} src={loja.fotoUrl} height={112} />
        </Box>
      )}
      <MenuItem
        to={`/loja_perfil/${loja.id}`}
        primaryText={nomeLoja}
        leftIcon={<StoreIcon />}
      />

      <FormControl variant="outlined" sx={{ m: 1, width: 220 }}>
        <InputLabel>{label}</InputLabel>
        <Select value={loja.id} onChange={handleSelect} label={label}>
          {usuario.lojas.map((l) => (
            <SelectMenuItem key={l.id} value={l.id}>
              {`${formatCNPJCPF(l.cnpj)} - ${l.nome_fantasia}`}
            </SelectMenuItem>
          ))}
        </Select>
      </FormControl>

      <MenuItem
        to="/"
        primaryText="Novidades"
        leftIcon={<RssFeedIcon />}
        sidebarIsOpen={open}
      />

      {admin_user_active ? (
        <>
          {publicarMenu(permissao, admin_user) && (
            <MenuItem
              to="/publicarMenu"
              primaryText="Publicar menu"
              leftIcon={<PublicIcon />}
              sidebarIsOpen={open}
            />
          )}

          {items
            .filter(
              (i) =>
                i.items &&
                i.items.reduce(
                  (acc, item) => acc || item.getMenu(permissao, admin_user),
                  false
                )
            )
            .map((i) => (
              <SubMenu
                key={i.name}
                name={i.name}
                icon={i.icon}
                handleToggle={() => handleToggle(i.name)}
                isOpen={state[i.name]}
                sidebarIsOpen={open}
                dense={dense}
              >
                {i.items
                  .filter((j) => j.getMenu(permissao, admin_user))
                  .map((j) => (
                    <MenuItem
                      {...j.getMenu(permissao, admin_user)}
                      sidebarIsOpen={open}
                      dense={dense}
                    />
                  ))}
              </SubMenu>
            ))}
        </>
      ) : (
        <Stack
          direction="column"
          alignItems="center"
          sx={{ m: 1, width: 220, textAlign: "center" }}
        >
          <h1>Opss...</h1>
          <p>
            Ocorreu um erro ao recuperar os dados. Entre em contato com o
            suporte e verifique a licença de uso.
          </p>
        </Stack>
      )}
      {isXSmall && logout}
      <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
        v{packageJSON.version}
      </Typography>
    </Box>
  );
};

export default withUser(Menu);
