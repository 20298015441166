import React from "react";
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  DateField,
  BooleanField,
} from "react-admin";

import EquipamentoFilter from "./EquipamentoFilter";
import { TotemOperacaoField } from "./TotemOperacaoInput";

const EquipamentoList = (props) => (
  <List
    {...props}
    filters={<EquipamentoFilter />}
    filterDefaultValues={{ ativo: true }}
    sort={{ field: "ultimo_uso", order: "DESC" }}
    exporter={false}
  >
    <Datagrid>
      <BooleanField source="ativo" label="Ativo" />
      <TextField source="id" label="Id" />
      <TextField source="nome" label="Nome" />
      {/* <TextField source="androidId" label="Android ID" /> */}
      {/* <NumberField source="nfce_serie" label="NFCe Série" /> */}
      {/* <NumberField source="nfce_nNF" label="NFCe última nota emitida" /> */}
      <TotemOperacaoField source="totem_operacao" label="Modo operação" />
      <DateField source="ultimo_uso" label="Último Uso" showTime />
      <EditButton />
      {/* <SincronizarButton /> */}
    </Datagrid>
  </List>
);

export default EquipamentoList;
