import React from "react";
import ClassIcon from "@material-ui/icons/Class";
import ComandaCreate from "./ComandaCreate";
import ComandaEdit from "./ComandaEdit";
import ComandaList from "./ComandaList";

const comanda = {
  getResource: (p) => {
    return {
      name: "comandas",
      create: p.cadastros_ver ? ComandaCreate : null,
      list: p.cadastros_ver ? ComandaList : null,
      edit: p.cadastros_ver ? ComandaEdit : null,
    };
  },
  getMenu: (p) =>
    p.cadastros_ver
      ? {
          key: "comandas",
          to: "/comandas",
          primaryText: "Comandas",
          leftIcon: iconComanda,
        }
      : null,
};

export default comanda;
export const iconComanda = <ClassIcon />;
