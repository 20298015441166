import React from "react";
import { TextInput, Filter, BooleanInput } from "react-admin";

const ComandaFilter = (props) => (
  <Filter {...props}>
    <TextInput source="codigo" label="Código" alwaysOn />
    <BooleanInput source="prepaga" />
    <BooleanInput source="bloqueada" />
    <BooleanInput source="conferencia" />
    <TextInput source="observacao_like" label="Observação" />
  </Filter>
);

export default ComandaFilter;
