import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
} from "@mui/material";

export const salesChannelsOptions = [
  { value: "LOJA", text: "Loja" },
  { value: "PALLAS", text: "Cardápio Digital" },
  { value: "IFOOD", text: "iFood" },
];

function SalesChannels({ value, onChange, id }) {
  const handleChange = (event) => onChange(id, event.target.value);

  return (
    <FormControl variant="standard" sx={{ minWidth: 150 }}>
      <InputLabel>Canais de Venda</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )}
      >
        {salesChannelsOptions.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SalesChannels;
