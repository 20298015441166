import React from "react";
import { formatDateTimeShort } from "../../common/util/formatter";
import useFetch from "../../dataProvider/useFetch";

function DeletadoPorDetalhes({ record }) {
  if (!record || !record.deletadoPorId) return null;
  return <DeletadoPor record={record} />;
}

function DeletadoPor({ record }) {
  const { data } = useFetch(`parceiros/${record.deletadoPorId}`, {
    filter: {
      where: { lojaId: record.lojaId },
      fields: [`nome_fantasia`],
    },
  });
  const nome = data ? data.nome_fantasia : "...";

  return (
    <small className="cancelado-detalhes">
      <br />
      {`Cancelado às ${formatDateTimeShort(record.deletado_em)} por '${nome}'.`}
      <br />
      {`Motivo: ${record.deletado_motivo}.`}
    </small>
  );
}

export default DeletadoPorDetalhes;
