import React from "react";
import { green, red, yellow } from "@mui/material/colors";
import { Chip } from "@mui/material";

function MovimentoStatus({ record }) {
  if (!record) return null;

  const { deletadoPorId, recebido_em } = record;

  if (deletadoPorId > 0) {
    return (
      <Chip
        size="small"
        label="Cancelado"
        style={{
          backgroundColor: red[200],
          borderColor: red[200],
        }}
      />
    );
  }

  if (recebido_em) {
    return (
      <Chip
        size="small"
        label="Pago"
        style={{
          backgroundColor: green[200],
          borderColor: green[200],
        }}
      />
    );
  }

  return (
    <Chip
      size="small"
      label="Aberto"
      style={{
        backgroundColor: yellow[200],
        borderColor: yellow[200],
      }}
    />
  );
}

export default MovimentoStatus;
