import React from "react";
import { Datagrid, List, TextField, BooleanField } from "react-admin";
import WhatsappDesinstalarButton from "./WhatsappDesinstalarButton";

const WhatsappList = (props) => (
  <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="number" />
      <BooleanField source="ok" />
      <TextField source="status" />
      <TextField source="loja.id" />
      <TextField source="loja.whatsapp" />
      <TextField source="fs.appDeliveryUri" />
      <WhatsappDesinstalarButton />
    </Datagrid>
  </List>
);

export default WhatsappList;
