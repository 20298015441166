import React from "react";
import { Link } from "react-router-dom";
import { SimpleForm, TextInput, DateTimeInput } from "react-admin";
import { Card, CardContent, Box, Typography } from "@material-ui/core";
import MovimentoDetalhes from "./MovimentoDetalhes";
import MovimentoNFCe from "./MovimentoNFCe";
import DeletadoPorDetalhes from "./DeletadoPorDetalhes";

function Parceiro({ record }) {
  if (!record.parceiro) {
    return (
      <Typography variant="h6" gutterBottom>
        Cliente: nenhum
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {`Cliente `}
        <Link
          to={`/parceiros/${record.parceiro.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          #{record.parceiro.id}
        </Link>
      </Typography>
      <Box display={{ xs: "block", sm: "flex" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="parceiro.nome_fantasia" label="Nome" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="parceiro.cnpj" label="CNPJ ou CPF" fullWidth />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="parceiro.telefone1" label="Telefone 1" fullWidth />
        </Box>
      </Box>
    </>
  );
}

function MovimentoForm(props) {
  const { record } = props;
  const cancelClass = record.deletadoPorId ? "cancelado" : "";

  return (
    <Card>
      <SimpleForm {...props} toolbar={props.toolbar} redirect="list">
        <CardContent fullWidth>
          <Box display={{ md: "block", lg: "flex" }}>
            <Box flex={2} mr={{ md: 0, lg: "1em" }}>
              <Typography variant="h6" gutterBottom className={cancelClass}>
                {`Movimento #${record.id}`}
                <DeletadoPorDetalhes record={record} />
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="nsp" label="NSP" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="mov" label="MOV" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput source="recebido_em" label="Data" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="comanda"
                    label="Comanda / Mesa"
                    fullWidth
                  />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="descricao" label="Descrição" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="observacao" label="Observação" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="identificador"
                    label="Identificador"
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="canal_venda" label="Canal" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="tipo_venda" label="Tipo" fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="cpf_cnpj" label="CPF / CNPJ" fullWidth />
                </Box>
              </Box>

              <MovimentoDetalhes record={record} />

              <Parceiro record={record} />

              <MovimentoNFCe record={record} />
            </Box>
          </Box>
        </CardContent>
      </SimpleForm>
    </Card>
  );
}

export default MovimentoForm;
