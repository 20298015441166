import React from "react";
import {
  NumberInput,
  Filter,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import QuickFilter from "../../common/form/QuickFilter";
import { salesChannelsOptions } from "../../reports/Search/SalesChannels";
import { serviceTypesOptions } from "../../reports/Search/ServiceTypes";

export const movimento_filters = {
  cancelado: {
    deletadoPorId: { gt: 0 },
  },
  com_desconto: {
    or: [{ total_desconto: { lt: 0 } }, { total_desconto_itens: { lt: 0 } }],
  },
};

const MovimentoFilter = (props) => (
  <Filter {...props}>
    <QuickFilter source="cancelado" label="Cancelado" defaultValue={true} />
    <QuickFilter
      source="com_desconto"
      label="Com Desconto"
      defaultValue={true}
    />
    <BooleanInput source="servico" label="Pagou Serviço" />

    <ReferenceInput label="Parceiro" source="parceiroId" reference="parceiros">
      <AutocompleteInput optionText="nome_fantasia" />
    </ReferenceInput>

    <ReferenceInput
      label="Equipamento"
      source="equipamentoId"
      reference="equipamentos"
      filter={{ ativo: true }}
      sort={{ field: "ultimo_uso", order: "DESC" }}
    >
      <AutocompleteInput optionText="nome" />
    </ReferenceInput>

    <SelectInput
      label="Canal Venda"
      source="canal_venda"
      choices={salesChannelsOptions.map((i) => ({ id: i.value, name: i.text }))}
      {...props}
    />

    <SelectInput
      label="Tipo Venda"
      source="tipo_venda"
      choices={serviceTypesOptions.map((i) => ({ id: i.value, name: i.text }))}
      {...props}
    />

    <NumberInput source="mov" label="MOV" />
    <NumberInput source="nsp" label="NSP" />
  </Filter>
);

export default MovimentoFilter;
