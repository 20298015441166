import WhatsappList from "./WhatsappList";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const whatsapp = {
  getResource: (p) => {
    if (p.ADMIN) {
      return {
        name: "Whatsapp",
        list: WhatsappList,
      };
    }
  },
  getMenu: (p) =>
    p.ADMIN
      ? {
          key: "Whatsapp",
          to: "/Whatsapp",
          primaryText: "Whatsapp",
          leftIcon: iconWhatsApp,
        }
      : null,
};

export default whatsapp;
export const iconWhatsApp = <WhatsAppIcon />;
