import React from "react";
import { Edit } from "react-admin";

import ComandaForm from "./ComandaForm";
import ComandaToolbar from "./ComandaToolbar";

const ComandaEdit = (props) => (
  <Edit {...props}>
    <ComandaForm toolbar={<ComandaToolbar />} />
  </Edit>
);

export default ComandaEdit;
