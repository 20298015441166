import React from "react";
import { Create } from "react-admin";

import ComandaForm from "./ComandaForm";
import ComandaToolbar from "./ComandaToolbar";

const ComandaCreate = (props) => (
  <Create {...props}>
    <ComandaForm toolbar={<ComandaToolbar />} />
  </Create>
);

export default ComandaCreate;
