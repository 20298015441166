import React, { useEffect } from "react";
import { Title, useNotify } from "react-admin";
import Delete from "@material-ui/icons/Delete";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SaveIcon from "@material-ui/icons/Save";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useFetch from "../dataProvider/useFetch";
import { withUser } from "../common/util/hocs";
import LoadingButton from "../common/LoadingButton";
import uxdelay from "../dataProvider/uxdelay";
import { fetchJSON } from "../dataProvider";
import { QRCodeCanvas } from "qrcode.react";
import { formatPhone } from "../common/util/formatter";

const useStyles = makeStyles((theme) => ({
  cover: {
    minHeight: "260px",
    height: "100%",
    backgroundColor: "#25d366",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  image: {
    height: "70%",
    width: "70%",
    color: "white",
  },
}));

const STATUS_READY = "ready";

export function WhatsAppLogo() {
  const classes = useStyles();

  return (
    <div className={classes.cover}>
      <WhatsAppIcon className={classes.image} />
    </div>
  );
}

function WhatsApp({ loja, admin_user }) {
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);
  const [config, setConfig] = React.useState(null);

  const {
    data: status,
    error,
    refetch: refetchStatus,
  } = useFetch("whatsapp/roboStatus", {
    lojaId: loja.id,
  });

  const serviceStatus = status && status.service ? status.service : null;
  const ready = serviceStatus && serviceStatus.status === STATUS_READY;

  useEffect(() => {
    if (ready || error) {
      return;
    }

    const timeout = setTimeout(refetchStatus, 1000);
    return () => clearTimeout(timeout);
  }, [refetchStatus, ready, error]);

  useEffect(() => {
    if (ready && config === null) {
      setConfig(status.respostas || {});
    }
  }, [status, ready, config]);

  const handleInstalar = () => {
    setLoading(true);
    uxdelay(
      fetchJSON(`whatsapp/roboInstala`, {
        method: "POST",
        body: { lojaId: loja.id },
      })
    )
      .then((json) => {
        refetchStatus();
        notify("Aguardando QRCode");
      })
      .catch((err) => {
        console.log(err);
        notify("Erro ao instalar o WhatsApp", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleDesinstalar = () => {
    setLoading(true);
    uxdelay(
      fetchJSON(`whatsapp/roboDesinstalar`, {
        method: "DELETE",
        body: { lojaId: loja.id },
      })
    )
      .then((json) => {
        refetchStatus();
        notify("WhatsApp desinstalado com sucesso!");
      })
      .catch((err) => {
        console.log(err);
        notify("Erro ao desinstalar o WhatsApp", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleSave = () => {
    setLoading(true);
    uxdelay(
      fetchJSON(`whatsapp/roboRespostas`, {
        method: "POST",
        body: { lojaId: loja.id, ...config },
      })
    )
      .then((json) => {
        refetchStatus();
        notify("WhatsApp configurado com sucesso!");
      })
      .catch((err) => {
        console.log(err);
        notify("Erro ao configurar o WhatsApp", "warning");
      })
      .finally(() => setLoading(false));
  };

  const handleChangeValue = (event) => {
    setConfig({
      ...config,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheckbox = (event) => {
    setConfig({
      ...config,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Card sx={{ display: "flex", p: 3 }}>
      <Title title="🤖 Chatbot no WhatsApp" />
      <Box sx={{ width: 1 / 4 }}>
        <WhatsAppLogo />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 3 / 4,
        }}
      >
        <CardContent sx={{ flex: "1 0 auto", px: 2, py: 0 }}>
          <Typography variant="h5" color="textPrimary" sx={{ mb: 2 }}>
            🤖 Chatbot no WhatsApp
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            O Chatbot responde às principais dúvidas dos seus clientes no
            WhatsApp e envia o link automaticamente para seu cliente realizar o
            pedido.
            <ul>
              <li>Mensagem de boas vindas com o link de pedidos.</li>
              <li>Horário de funcionamento.</li>
              <li>Valor da taxa de entrega.</li>
              <li>Dúvidas sobre os produtos.</li>
            </ul>
          </Typography>
          {status && (
            <Typography variant="h6" sx={{ mt: 5, mb: 2 }}>
              {`Número: ${formatPhone(status.whatsapp)}`}
              {serviceStatus && serviceStatus.status && (
                <Chip
                  sx={{ mx: 2 }}
                  label={serviceStatus.status}
                  color={ready ? "success" : "default"}
                />
              )}
              {/* {serviceStatus && serviceStatus.statusDetails && (
                <small>
                  {formatDateTime(serviceStatus.statusDetails.lastUpdate)}
                </small>
              )} */}
            </Typography>
          )}
          {serviceStatus && serviceStatus.status === "qr" && (
            <QRCodeCanvas
              value={serviceStatus.statusDetails.qrCode}
              size={256}
            />
          )}
          {ready && config && (
            <Box sx={{ mb: 5 }}>
              <FormControlLabel
                sx={{ mb: 3 }}
                control={
                  <Checkbox
                    name="responder"
                    onChange={handleChangeCheckbox}
                    checked={config.responder}
                  />
                }
                label="Pode responder"
              />
              <TextField
                label="Resposta Bem Vindo"
                variant="filled"
                name="respostaBemVindo"
                onChange={handleChangeValue}
                value={config.respostaBemVindo}
                fullWidth
                multiline
              />
            </Box>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{ my: 2 }}>
              {error.message}
            </Typography>
          )}
        </CardContent>
        <CardActions sx={{ px: 2, py: 0, justifyContent: "space-between" }}>
          {admin_user.chatbot && !status && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleInstalar}
              text="Instalar"
              loading={loading}
            />
          )}
          {admin_user.chatbot && ready && config && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              icon={<SaveIcon />}
              text="Salvar"
              loading={loading}
            />
          )}
          {!admin_user.chatbot && status && (
            <LoadingButton
              variant="outlined"
              color="error"
              onClick={handleDesinstalar}
              icon={<Delete />}
              text="Desinstalar"
              loading={loading}
            />
          )}
        </CardActions>
      </Box>
    </Card>
  );
}

export default withUser(WhatsApp);
