import React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  EditButton,
  SingleFieldList,
  ReferenceManyField,
} from "react-admin";
import CurrencyField from "../../common/form/CurrencyField";

import MovimentoFilter from "./MovimentoFilter";
import NFCeStatus from "../nfce/NFCeStatus";
import MovimentoStatus from "./MovimentoStatus";

const MovimentoList = (props) => (
  <List
    {...props}
    filters={<MovimentoFilter />}
    sort={{ field: "recebido_em", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="mov" />
      <DateField
        source="recebido_em"
        label="Criado em"
        showTime
        locales="pt-BR"
      />
      <MovimentoStatus />
      <CurrencyField source="total_bruto" label="Total" />

      <ReferenceManyField
        reference="NFCes"
        target="movimentoId"
        label="NFCe Status"
        fullWidth
      >
        <SingleFieldList linkType="show">
          <NFCeStatus />
        </SingleFieldList>
      </ReferenceManyField>
      <EditButton />
    </Datagrid>
  </List>
);

export default MovimentoList;
